<template>
    <div class="overflow-x-auto p-4">
      <div :class="isLoading ? 'hidden' : 'block'">

          <div class="relative sm:w-72 w-full">
              <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
              </svg>
              <input
                type="search"
                v-model="InputValue"
                @input="Search()"
                placeholder="Recherche par étudiant"
                class="focus:border-light-blue-500 focus:ring-1 focus:ring-red-400 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-300 rounded-md py-2 pl-10 "
              >
          </div>
            

        <div v-if="userSelected">
          <delete-modal :userSelectedData="userSelectedData" :closeDeleteUser="closeDeleteUser"/>
        </div>
        


        <div class="min-w-screen flex items-center justify-center bg-white font-sans overflow-hidden mx-2 mt-4 mb-2 rounded">
            <div class="w-full">
                <div class="bg-white rounded">
                    <table class="min-w-max w-full table-auto hidden sm:inline-table">
                        <thead>
                            <tr class="bg-white border-b border-t text-gray-600 uppercase text-sm leading-normal">
                                <th class="py-3 px-6 text-center">Nom complet</th>
                                <th class="py-3 px-6 text-center">Année d'étude</th>
                                <th class="py-3 px-6 text-center">Université</th>
                                <th class="py-3 px-6 text-center">Abonnement</th>
                                <th class="py-3 px-6 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody  class="text-gray-600 text-sm font-light">
                            <tr v-for="item in items" :key="item.id" class="border-b border-gray-200 hover:bg-gray-100">
                                <td class="py-3 px-6 text-left whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="mr-2">
                                          <img class="w-6 h-6 rounded-full border-gray-200 border transform hover:scale-125" :src="item.profil_image == null ? '/avatar_profile.jpg' :  item.profil_image"/>
                                            <!-- <img class="w-6 h-6 rounded-full border-gray-200 border transform hover:scale-125" src="https://randomuser.me/api/portraits/men/1.jpg"/> -->
                                        </div>
                                        <span class="font-medium">{{ item.lastname }} {{ item.firstname }}</span>
                                    </div>
                                </td>
                                <td class="py-3 px-6 text-center">
                                    <div class="flex justify-center items-center"  :class="item.year === null ? 'text-red-400' : 'text-gray-600'">
                                        <span v-if="item.year === null">Non mentionnée</span>
                                        <span v-else>{{ item.year }} Année</span>
                                    </div>
                                </td>
                                <td class="py-3 px-6 text-center">
                                    <div class="flex justify-center items-center" :class="item.university === null ? 'text-red-400' : 'text-gray-600'">
                                        <span v-if="item.university === null">Non mentionnée</span>
                                        <span v-else>Faculté de {{ item.university }}</span>
                                    </div>
                                </td>
                                <td class="py-3 px-6 text-center">
                                    <span v-if="(item.state == 0)" class="bg-red-200 text-red-600 py-1 px-3 rounded-full text-xs">Inactive</span>
                                    <span v-else class="bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs">Actif</span>

                                </td>
                                <td class="py-3 px-6 text-center">
                                    <div class="flex item-center justify-center">
                                        <div class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                            </svg>
                                        </div>
                                        <!-- <div class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                            </svg>
                                        </div> -->
                                        <div @click="openDeleteUser(item.lastname,item.firstname,item.id)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                        </div>
                                    </div>
                                </td>
                            </tr>          
                        </tbody>
                    </table>

                    <table class="min-w-max w-full table-auto sm:hidden inline-table">
                        <thead>
                            <tr class="bg-white border-b border-t text-gray-600 uppercase text-sm leading-normal">
                                <th class="py-3 px-6 text-center">Nom complet</th>
                                <th class="py-3 px-6 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody  class="text-gray-600 text-sm font-light">
                            <tr v-for="item in items" :key="item.id" class="border-b border-gray-200 hover:bg-gray-100">
                                <td class="py-3 px-6 text-left whitespace-nowrap">
                                    <div class="flex items-center">
                                        <div class="mr-2">
                                          <img class="w-6 h-6 rounded-full border-gray-200 border transform hover:scale-125" :src="item.profil_image == null ? '/avatar_profile.jpg' :  item.profil_image"/>
                                            <!-- <img class="w-6 h-6 rounded-full border-gray-200 border transform hover:scale-125" src="https://randomuser.me/api/portraits/men/1.jpg"/> -->
                                        </div>
                                        <span class="font-medium">{{ item.lastname }} {{ item.firstname }}</span>
                                    </div>
                                </td>
                                <td class="py-3 px-6 text-center">
                                    <div class="flex item-center justify-center">
                                        <div class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                            </svg>
                                        </div>
                                        <!-- <div class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                            </svg>
                                        </div> -->
                                        <div @click="openDeleteUser(item.lastname,item.firstname,item.id)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                        </div>
                                    </div>
                                </td>
                            </tr>          
                        </tbody>
                    </table> 
                     
                </div>

                
                <ul class="relative z-0 inline-flex rounded-md -space-x-px w-full justify-center mt-4">
                    <li>
                      <button :disabled="prevp === null"
                        class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                        @click.prevent="prevPage">
                        <span class="sr-only">Previous</span>
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clip-rule="evenodd" />
                        </svg>
                      </button>
                    </li>
                    <li class="flex flex-row">
                      <button
                        class=" hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-700"
                        @click.prevent="refine(1)">
                        1
                      </button>
                      <input :max="lastp" @input="refine(currentp)"
                        class="w-14 hover:bg-gray-50 relative inline-flex text-center items-center border text-sm font-medium bg-white border-gray-300 text-gray-700"
                        v-model="currentp" placeholder="page" />
                      <button
                        class=" hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium bg-white border-gray-300 text-gray-700"
                        @click.prevent="refine(lastp)">
                        {{ lastp }}
                      </button>
                    </li>
                    
                    <li>
                      <button
                        class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
                        @click.prevent="nextPage">
                        <span class="sr-only">Next</span>
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd" />
                        </svg>
                      </button>
                    </li>
               </ul>


            </div>
        </div>
        </div>


        <div
            :class="isLoading ? 'block' : 'hidden'"
            class="
            min-h-screen
            flex
            items-center
            justify-center
            bg-white
            py-12
            px-4
            sm:px-6
            lg:px-8
        "
        >
            <!--<div class="fulfilling-square-spinner">
                <div class="spinner-inner"></div>
            </div>-->
            <div class="loading">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
        </div>


    </div>
</template>

<script>
import axios from "axios";
import DeleteModal from './DeleteModal.vue'
import { ElMessage } from 'element-plus';

export default {
  components: {
    DeleteModal,
  },
  data () {
     return {
        currentRoute: window.location.pathname,
        students: [],
        isLoading: false,
        items : [],
        userSelected : false,
        userSelectedData: {
          id: null,
          lastname : null,
          firstname: null,
        },
       lastp: null,
       nextp: null,
       currentp: null,
       prevp: null,
       InputValue : null,
       timer : null,
       componentKey: 0
     }
   },

  props:{
    linkPath:Function,
  },

  mounted () {
    this.linkPath(this.currentRoute)
    axios
      .get(process.env.VUE_APP_API_BASE_URL+"/getusers", {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
      .then(({data}) => {
        this.items = data.students.data
        this.lastp = data.students.last_page;
        this.nextp = data.students.next_page_url;
        this.currentp = data.students.current_page;
         this.isLoading = false;
      })
      .catch(() => {
        this.errorm();
        this.isLoading = false;
      });
  },
  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
  },


  methods:{
    Search(){
      if (this.InputValue == '') {

        axios
          .get(process.env.VUE_APP_API_BASE_URL+"/getusers", {
            headers: {
              Authorization: "Bearer" + this.isToken,
            },
          })
          .then(({ data }) => {
            this.items = data.students.data
            this.lastp = data.students.last_page;
            this.nextp = data.students.next_page_url;
            this.currentp = data.students.current_page;
            this.isLoading = false;
          })
          .catch(() => {
            this.errorm();
            this.isLoading = false;
          });
      }else{
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          axios
            .get(process.env.VUE_APP_API_BASE_URL+"/getfiltredusers/" + this.InputValue, {
              headers: {
                Authorization: "Bearer" + this.isToken,
              },
            })
            .then(({ data }) => {
              this.items = data.students.data
              this.lastp = data.students.last_page;
              this.nextp = data.students.next_page_url;
              this.currentp = data.students.current_page;
              this.isLoading = false;
            })
            .catch(() => {
              this.errorm();
              this.isLoading = false;
            });
        }, 800);
      }
      
    },
    nextPage() {
      axios.get(this.nextp, {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
        .then(({ data }) => {
          this.items = data.students.data;
          this.lastp = data.students.last_page;
          this.nextp = data.students.next_page_url;
          this.prevp = data.students.prev_page_url;
          this.currentp = data.students.current_page;
        })
        .catch(() => {
          this.errorm();
        });
    },

    prevPage() {
      axios.get(this.prevp, {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
        .then(({ data }) => {
          this.items = data.students.data;
          this.lastp = data.students.last_page;
          this.nextp = data.students.next_page_url;
          this.prevp = data.students.prev_page_url;
          this.currentp = data.students.current_page;
        })
        .catch(() => {
          this.errorm();
        });
    },

    refine(page) {
      
      if (this.InputValue == '' || this.InputValue == null ){
      axios.get(process.env.VUE_APP_API_BASE_URL+"/getusers?page=" + page, {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
        .then(({ data }) => {
          this.items = data.students.data;
          this.lastp = data.students.last_page;
          this.nextp = data.students.next_page_url;
          this.prevp = data.students.prev_page_url;
          this.currentp = data.students.current_page;
        })
        .catch(() => {
          this.errorm();
        });
      }else{
        axios
          .get(process.env.VUE_APP_API_BASE_URL+"/getfiltredusers/" + this.InputValue +"?page="+page, {
            headers: {
              Authorization: "Bearer" + this.isToken,
            },
          })
          .then(({ data }) => {
            this.items = data.students.data
            this.lastp = data.students.last_page;
            this.nextp = data.students.next_page_url;
            this.currentp = data.students.current_page;
            this.isLoading = false;
          })
          .catch(() => {
            this.errorm();
            this.isLoading = false;
          });
      }
    },
    openDeleteUser(lastname, firstname, id){

      this.userSelected = true;
      this.userSelectedData.lastname = lastname;
      this.userSelectedData.firstname = firstname;
      this.userSelectedData.id = id;
    },

    closeDeleteUser(){
      this.userSelected = false
      this.userSelectedData.lastname = null;
      this.userSelectedData.firstname = null;
      this.userSelectedData.id = null;
    },

    deleteUser(id){
      axios
        .delete(process.env.VUE_APP_API_BASE_URL+"/auth/delete/"+id, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(() => {
          this.closeDeleteUser();
        })
        .catch(() => {
          this.errorm();
          this.isLoading = false;
        });
    }
  },

  
  setup(){
    const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }

    return{errorm}
  }
}

</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>