<template>
    <div>
        <div class="fixed inset-0 z-10 bg-red-200" style="opacity: 0.5" aria-hidden="true"></div>
            <!--   delete modal   -->
        <div class="z-50 inset-0 fixed flex justify-center items-center">
          <div class=" flex flex-col w-16/12 sm:w-5/6 lg:w-1/2 max-w-md mx-auto rounded-lg border border-gray-300 shadow-xl">
            <div class=" flex flex-row justify-between items-center px-3 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
              <p class="font-semibold text-sm text-gray-800">Supprimer un compte</p>
              <svg @click="closeDeleteUser" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
            <div class="flex flex-col px-6 py-5 bg-gray-50">
              <div class=" flex flex-col sm:flex-row items-center text-center text-sm p-3 sm:space-x-5">   
                <div class="w-full">
                  Voulez vous vraiment supprimer l'étudiat<br>
                  <span class="font-semibold">{{userSelectedData.lastname}} {{userSelectedData.firstname}} </span>
                  de votre liste d'étudiants
                </div>
              </div>
            </div>
            <div
              class=" flex flex-row items-center justify-between px-6 py-3 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg "
            >
              <button
                @click="closeDeleteUser"
                class="font-semibold text-gray-600 text-sm"
              >
                Annuler
              </button>
              <button @click="deleteUser(userSelectedData.id)" class=" text-sm px-4 text-red-400 font-semibold bg-white rounded"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import { ElMessage } from 'element-plus';
import axios from 'axios'

const connectRefresh = (renderFn, unmountFn) => (widgetParams = {}) => ({
  init() {
    renderFn({ refresh() {} }, true);
  },

  render({ instantSearchInstance }) {
    const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);

    renderFn({ refresh }, false);
  },

  dispose() {
    unmountFn();
  },
});

export default {
  name: 'AisStateResults',
  mixins: [createWidgetMixin({ connector: connectRefresh })],
  props:{
    userSelectedData:Object,
    closeDeleteUser:Function
  },
  methods: {
    refresh() {
      let vm = this;
      setTimeout(function() {
        vm.state.refresh();
        }, 4000)
    },

    deleteUser(id){
      axios
        .delete(process.env.VUE_APP_API_BASE_URL+"/auth/delete/"+id, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({  }) => {
          this.closeDeleteUser();
          this.refresh()
        })
        .catch(() => {
          this.errorm();
          this.isLoading = false;
        });

       


    }
  },
  setup(){
     const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }

    return{errorm}
  }
};
</script>
